.ace-c-contract-partner-reload-button {
    background-color: #fff;
    border: 1px solid #252ef8;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    z-index: 1;

    &--is-disabled {
        color: #bebebf;
        background: #efeff0;
        border: 1px solid #bebebf;
        cursor: default;
    }
}
