@import '../config';
@import '../mixins';
@import './NumberInput.module';
@import './input-currency-config';

.ace-c-input-currency {
    @include typography($typography-styles, map-get($input-currency-config, input-currency-text-typography));
}

.ace-c-number-input__native-input,
.ace-c-number-input__formatted-value {
    @include typography($typography-styles, map-get($input-currency-config, input-currency-text-typography));

    padding-right: map-get($input-currency-config, input-currency-input-padding-right);

    .ace-c-input-currency--small & {
        height: 40px;
    }

    .ace-c-input-currency--has-error & {
        color: map-get($input-currency-config, input-currency-input-color-error);
        border: 1px solid map-get($input-currency-config, input-currency-input-border-color-error);
        box-shadow: map-get($input-currency-config, input-currency-input-box-shadow-error);
    }
}

.ace-c-input-currency__currency-sign {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);

    &--is-disabled {
        color: map-get($input-currency-config, input-currency-currency-sign-disabled);
    }

    &--has-error {
        color: map-get($input-currency-config, input-currency-currency-sign-error);
    }
}
